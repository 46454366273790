import React from "react";
import { useBagStore } from "./bagState";

const BagCount: React.FC = () => {
  const entries = useBagStore((state) => state.entries);

  return (
    entries.length > 0 && (
      <>
        <span className="absolute right-0 top-0 h-4 w-4 animate-ping rounded-full bg-orange opacity-40" />
        <span className="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-orange text-xs text-white">
          {entries.reduce((acc, product) => acc + product.count, 0)}
        </span>
      </>
    )
  );
};

export default BagCount;
